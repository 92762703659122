export const BothArrows = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 70 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="35" cy="35" r="35" fill="#8085FF" />
      <path
        d="M44.9393 53.0607C45.5251 53.6464 46.4749 53.6464 47.0607 53.0607L56.6066 43.5147C57.1924 42.9289 57.1924 41.9792 56.6066 41.3934C56.0208 40.8076 55.0711 40.8076 54.4853 41.3934L46 49.8787L37.5147 41.3934C36.9289 40.8076 35.9792 40.8076 35.3934 41.3934C34.8076 41.9792 34.8076 42.9289 35.3934 43.5147L44.9393 53.0607ZM44.5 27V52H47.5V27H44.5Z"
        fill="white"
      />
      <path
        d="M25.0607 16.9393C24.4749 16.3536 23.5251 16.3536 22.9393 16.9393L13.3934 26.4853C12.8076 27.0711 12.8076 28.0208 13.3934 28.6066C13.9792 29.1924 14.9289 29.1924 15.5147 28.6066L24 20.1213L32.4853 28.6066C33.0711 29.1924 34.0208 29.1924 34.6066 28.6066C35.1924 28.0208 35.1924 27.0711 34.6066 26.4853L25.0607 16.9393ZM25.5 43V18H22.5V43H25.5Z"
        fill="white"
      />
    </svg>
  );
};
export const Cancel = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.14645 2.85355C1.95118 2.65829 1.95118 2.34171 2.14645 2.14645C2.34171 1.95118 2.65829 1.95118 2.85355 2.14645L8 7.29289L13.1464 2.14645C13.3417 1.95118 13.6583 1.95118 13.8536 2.14645C14.0488 2.34171 14.0488 2.65829 13.8536 2.85355L8.70711 8L13.8536 13.1464C14.0488 13.3417 14.0488 13.6583 13.8536 13.8536C13.6583 14.0488 13.3417 14.0488 13.1464 13.8536L8 8.70711L2.85355 13.8536C2.65829 14.0488 2.34171 14.0488 2.14645 13.8536C1.95119 13.6583 1.95119 13.3417 2.14645 13.1464L7.29289 8L2.14645 2.85355Z"
        fill="black"
      />
    </svg>
  );
};
export const SearchSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7422 10.3439C12.5329 9.2673 13 7.9382 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13C7.93858 13 9.26801 12.5327 10.3448 11.7415L10.3439 11.7422C10.3734 11.7822 10.4062 11.8204 10.4424 11.8566L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L11.8566 10.4424C11.8204 10.4062 11.7822 10.3734 11.7422 10.3439ZM12 6.5C12 9.53757 9.53757 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1C9.53757 1 12 3.46243 12 6.5Z"
        fill="black"
      />
    </svg>
  );
};
export const BackSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.3536 1.64645C11.5488 1.84171 11.5488 2.15829 11.3536 2.35355L5.70711 8L11.3536 13.6464C11.5488 13.8417 11.5488 14.1583 11.3536 14.3536C11.1583 14.5488 10.8417 14.5488 10.6464 14.3536L4.64645 8.35355C4.45118 8.15829 4.45118 7.84171 4.64645 7.64645L10.6464 1.64645C10.8417 1.45118 11.1583 1.45118 11.3536 1.64645Z"
        fill="black"
      />
    </svg>
  );
};
export const PlusSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 2C8.27614 2 8.5 2.22386 8.5 2.5V7.5H13.5C13.7761 7.5 14 7.72386 14 8C14 8.27614 13.7761 8.5 13.5 8.5H8.5V13.5C8.5 13.7761 8.27614 14 8 14C7.72386 14 7.5 13.7761 7.5 13.5V8.5H2.5C2.22386 8.5 2 8.27614 2 8C2 7.72386 2.22386 7.5 2.5 7.5H7.5V2.5C7.5 2.22386 7.72386 2 8 2Z"
        fill="black"
      />
    </svg>
  );
};
export const WarningSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM8 4C7.46459 4 7.04623 4.46229 7.0995 4.99504L7.45025 8.50248C7.47849 8.78492 7.71616 9 8 9C8.28384 9 8.52151 8.78492 8.54975 8.50248L8.9005 4.99504C8.95377 4.46228 8.53541 4 8 4ZM8.00154 10C7.44926 10 7.00154 10.4477 7.00154 11C7.00154 11.5523 7.44926 12 8.00154 12C8.55383 12 9.00154 11.5523 9.00154 11C9.00154 10.4477 8.55383 10 8.00154 10Z"
        fill="#BBBE03"
      />
    </svg>
  );
};
export const CancelSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4602 0.146447C11.3664 0.0526784 11.2392 0 11.1066 0H4.8934C4.76079 0 4.63361 0.0526784 4.53984 0.146447L0.146447 4.53984C0.0526784 4.63361 0 4.76079 0 4.8934V11.1066C0 11.2392 0.0526784 11.3664 0.146447 11.4602L4.53984 15.8536C4.63361 15.9473 4.76079 16 4.8934 16H11.1066C11.2392 16 11.3664 15.9473 11.4602 15.8536L15.8536 11.4602C15.9473 11.3664 16 11.2392 16 11.1066V4.8934C16 4.76079 15.9473 4.63361 15.8536 4.53984L11.4602 0.146447ZM5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645Z"
        fill="#EC0606"
      />
    </svg>
  );
};
export const SuccessSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM12.0303 4.96967C12.3196 5.25897 12.3232 5.72582 12.041 6.01947L8.04876 11.0097C8.043 11.0169 8.03685 11.0238 8.03032 11.0303C7.73743 11.3232 7.26256 11.3232 6.96966 11.0303L4.32322 8.38388C4.03032 8.09099 4.03032 7.61612 4.32322 7.32322C4.61611 7.03033 5.09098 7.03033 5.38388 7.32322L7.4774 9.41674L10.9498 4.9921C10.9559 4.98424 10.9626 4.97674 10.9697 4.96967C11.2626 4.67678 11.7374 4.67678 12.0303 4.96967Z"
        fill="#047F1F"
      />
    </svg>
  );
};
export const PersonSvg = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 6C7.65685 6 9 4.65685 9 3C9 1.34315 7.65685 0 6 0C4.34315 0 3 1.34315 3 3C3 4.65685 4.34315 6 6 6ZM8 3C8 4.10457 7.10457 5 6 5C4.89543 5 4 4.10457 4 3C4 1.89543 4.89543 1 6 1C7.10457 1 8 1.89543 8 3Z"
        fill="blue"
      />
      <path
        d="M12 11C12 12 11 12 11 12H1C1 12 0 12 0 11C0 10 1 7 6 7C11 7 12 10 12 11ZM11 10.9965C10.9986 10.7497 10.8462 10.0104 10.1679 9.33211C9.51563 8.67985 8.2891 8 5.99999 8C3.71088 8 2.48435 8.67985 1.8321 9.33211C1.15375 10.0104 1.00142 10.7497 1 10.9965H11Z"
        fill="blue"
      />
    </svg>
  );
};
export const BankSvg = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.00004 0L14.6099 3H15.5C15.7762 3 16 3.22386 16 3.5L16 5.5C16 5.77614 15.7761 6 15.5 6H15L15 13C15.2295 13 15.4295 13.1574 15.4851 13.38L15.9851 15.38C16.0224 15.5294 15.9889 15.6886 15.8941 15.81C15.7994 15.9314 15.654 16 15.5 16H0.500035C0.346068 16 0.200686 15.9314 0.105933 15.81C0.011179 15.6886 -0.0223786 15.5294 0.014964 15.38L0.514964 13.38C0.57061 13.1574 0.770601 13 1.00004 13L1 6H0.5C0.223858 6 0 5.77614 0 5.5L3.52859e-05 3.5C3.52859e-05 3.22386 0.223893 3 0.500035 3H1.39022L8.00004 0ZM3.77652 3H12.2235L8.00004 1L3.77652 3ZM2 6L2.00004 13H3.00004L3 6H2ZM4 6L4.00004 13H6.50004L6.5 6H4ZM7.5 6L7.50004 13H8.50004L8.5 6H7.5ZM9.5 6L9.50004 13H12L12 6H9.5ZM13 6L13 13H14V6H13ZM15 5V4H1.00004V5H15ZM14.6096 14H1.39042L1.14042 15H14.8596L14.6096 14Z"
        fill="blue"
      />
    </svg>
  );
};
export const CardSvg = () => {
  return (
    <svg
      width="35"
      height="22"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29572 11.2696C0.871969 11.2696 0.527344 10.9249 0.527344 10.5012V1.1247C0.527344 0.700948 0.871969 0.356323 1.29572 0.356323H16.25C16.6737 0.356323 17.0183 0.700948 17.0183 1.1247V10.5012C17.0183 10.9249 16.6737 11.2696 16.25 11.2696H1.29572Z"
        fill="white"
      />
      <path
        d="M16.25 0.543823C16.5702 0.543823 16.8308 0.804448 16.8308 1.1247V10.5008C16.8308 10.8211 16.5702 11.0817 16.25 11.0817H1.29572C0.975469 11.0817 0.714844 10.8211 0.714844 10.5008V1.1247C0.714844 0.804448 0.975469 0.543823 1.29572 0.543823H16.25ZM16.25 0.168823H1.29572C0.769969 0.168823 0.339844 0.598948 0.339844 1.1247V10.5008C0.339844 11.0266 0.769969 11.4567 1.29572 11.4567H16.25C16.7757 11.4567 17.2058 11.0266 17.2058 10.5008V1.1247C17.2058 0.598948 16.7757 0.168823 16.25 0.168823Z"
        fill="#AAC1CE"
      />
      <path
        d="M0.339844 4.55591V1.92041H17.2058V4.55591H0.339844Z"
        fill="black"
      />
      <path
        d="M2.21191 6.56335H8.22504V7.09061H2.21191V6.56335Z"
        fill="black"
      />
      <path
        d="M2.21191 8.04797H5.83216V8.57522H2.21191V8.04797Z"
        fill="black"
      />
      <path
        d="M11.4892 9.77665C12.482 9.77665 13.2869 8.97177 13.2869 7.9789C13.2869 6.98603 12.482 6.18115 11.4892 6.18115C10.4963 6.18115 9.69141 6.98603 9.69141 7.9789C9.69141 8.97177 10.4963 9.77665 11.4892 9.77665Z"
        fill="#FFD07D"
      />
      <path
        d="M13.6786 9.77665C14.6715 9.77665 15.4764 8.97177 15.4764 7.9789C15.4764 6.98603 14.6715 6.18115 13.6786 6.18115C12.6857 6.18115 11.8809 6.98603 11.8809 7.9789C11.8809 8.97177 12.6857 9.77665 13.6786 9.77665Z"
        fill="#E04F5F"
      />
    </svg>
  );
};
export const InfoSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="black"
      />
      <path
        d="M7.00154 11C7.00154 10.4477 7.44926 10 8.00154 10C8.55383 10 9.00154 10.4477 9.00154 11C9.00154 11.5523 8.55383 12 8.00154 12C7.44926 12 7.00154 11.5523 7.00154 11Z"
        fill="black"
      />
      <path
        d="M7.0995 4.99504C7.04623 4.46228 7.46459 4 8 4C8.53541 4 8.95377 4.46228 8.9005 4.99504L8.54975 8.50248C8.52151 8.78492 8.28384 9 8 9C7.71616 9 7.47849 8.78492 7.45025 8.50248L7.0995 4.99504Z"
        fill="black"
      />
    </svg>
  );
};
export const BigSucessSvg = () => {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 25C50 38.8071 38.8071 50 25 50C11.1929 50 0 38.8071 0 25C0 11.1929 11.1929 0 25 0C38.8071 0 50 11.1929 50 25ZM37.5948 15.5302C36.6795 14.6149 35.1955 14.6149 34.2802 15.5302C34.2581 15.5523 34.2373 15.5757 34.218 15.6003L23.3669 29.4273L16.8246 22.8851C15.9093 21.9698 14.4253 21.9698 13.51 22.8851C12.5948 23.8004 12.5948 25.2843 13.51 26.1996L21.7802 34.4698C22.6955 35.3851 24.1795 35.3851 25.0948 34.4698C25.1151 34.4494 25.1344 34.4279 25.1524 34.4054L37.628 18.8109C38.5099 17.8932 38.4988 16.4343 37.5948 15.5302Z"
        fill="#00DE31"
      />
    </svg>
  );
};
export const ShareSvg = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2.5C11 1.11929 12.1193 0 13.5 0C14.8807 0 16 1.11929 16 2.5C16 3.88071 14.8807 5 13.5 5C12.7411 5 12.0611 4.66182 11.6026 4.12792L4.88498 7.2482C4.95971 7.48549 5 7.73804 5 8C5 8.26196 4.95971 8.51451 4.88498 8.7518L11.6026 11.8721C12.0611 11.3382 12.7411 11 13.5 11C14.8807 11 16 12.1193 16 13.5C16 14.8807 14.8807 16 13.5 16C12.1193 16 11 14.8807 11 13.5C11 13.238 11.0403 12.9855 11.115 12.7482L4.39739 9.62792C3.93889 10.1618 3.25894 10.5 2.5 10.5C1.11929 10.5 0 9.38071 0 8C0 6.61929 1.11929 5.5 2.5 5.5C3.25894 5.5 3.93889 5.83818 4.39739 6.37208L11.115 3.2518C11.0403 3.01451 11 2.76196 11 2.5Z"
        fill="#1677FF"
      />
    </svg>
  );
};
export const TransferSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 4.50001C3 4.22386 3.22386 4.00001 3.5 4.00001H9.5C9.77614 4.00001 10 4.22386 10 4.50001C10 4.77615 9.77614 5.00001 9.5 5.00001H3.5C3.22386 5.00001 3 4.77615 3 4.50001Z"
        fill="black"
      />
      <path
        d="M3 6.50001C3 6.22386 3.22386 6.00001 3.5 6.00001H9.5C9.77614 6.00001 10 6.22386 10 6.50001C10 6.77615 9.77614 7.00001 9.5 7.00001H3.5C3.22386 7.00001 3 6.77615 3 6.50001Z"
        fill="black"
      />
      <path
        d="M3 8.50001C3 8.22386 3.22386 8.00001 3.5 8.00001H9.5C9.77614 8.00001 10 8.22386 10 8.50001C10 8.77615 9.77614 9.00001 9.5 9.00001H3.5C3.22386 9.00001 3 8.77615 3 8.50001Z"
        fill="black"
      />
      <path
        d="M3 10.5C3 10.2239 3.22386 10 3.5 10H9.5C9.77614 10 10 10.2239 10 10.5C10 10.7761 9.77614 11 9.5 11H3.5C3.22386 11 3 10.7761 3 10.5Z"
        fill="black"
      />
      <path
        d="M3 12.5C3 12.2239 3.22386 12 3.5 12H9.5C9.77614 12 10 12.2239 10 12.5C10 12.7761 9.77614 13 9.5 13H3.5C3.22386 13 3 12.7761 3 12.5Z"
        fill="black"
      />
      <path
        d="M11.5 4.00001C11.2239 4.00001 11 4.22386 11 4.50001C11 4.77615 11.2239 5.00001 11.5 5.00001H12.5C12.7761 5.00001 13 4.77615 13 4.50001C13 4.22386 12.7761 4.00001 12.5 4.00001H11.5Z"
        fill="black"
      />
      <path
        d="M11.5 6.00001C11.2239 6.00001 11 6.22386 11 6.50001C11 6.77615 11.2239 7.00001 11.5 7.00001H12.5C12.7761 7.00001 13 6.77615 13 6.50001C13 6.22386 12.7761 6.00001 12.5 6.00001H11.5Z"
        fill="black"
      />
      <path
        d="M11.5 8.00001C11.2239 8.00001 11 8.22386 11 8.50001C11 8.77615 11.2239 9.00001 11.5 9.00001H12.5C12.7761 9.00001 13 8.77615 13 8.50001C13 8.22386 12.7761 8.00001 12.5 8.00001H11.5Z"
        fill="black"
      />
      <path
        d="M11.5 10C11.2239 10 11 10.2239 11 10.5C11 10.7761 11.2239 11 11.5 11H12.5C12.7761 11 13 10.7761 13 10.5C13 10.2239 12.7761 10 12.5 10H11.5Z"
        fill="black"
      />
      <path
        d="M11.5 12C11.2239 12 11 12.2239 11 12.5C11 12.7761 11.2239 13 11.5 13H12.5C12.7761 13 13 12.7761 13 12.5C13 12.2239 12.7761 12 12.5 12H11.5Z"
        fill="black"
      />
      <path
        d="M2.35355 0.646453C2.23991 0.532805 2.07856 0.480718 1.91991 0.506462C1.76126 0.532207 1.62466 0.632645 1.55279 0.776399L1.05279 1.7764C1.01807 1.84583 1 1.92238 1 2.00001V15H0.5C0.223858 15 0 15.2239 0 15.5C0 15.7761 0.223858 16 0.5 16H15.5C15.7761 16 16 15.7761 16 15.5C16 15.2239 15.7761 15 15.5 15H15V2.00001C15 1.92238 14.9819 1.84583 14.9472 1.7764L14.4472 0.776399C14.3753 0.632645 14.2387 0.532207 14.0801 0.506462C13.9214 0.480718 13.7601 0.532805 13.6464 0.646453L13 1.2929L12.3536 0.646453C12.1583 0.45119 11.8417 0.45119 11.6464 0.646453L11 1.2929L10.3536 0.646453C10.1583 0.45119 9.84171 0.45119 9.64645 0.646453L9 1.2929L8.35355 0.646453C8.15829 0.45119 7.84171 0.45119 7.64645 0.646453L7 1.2929L6.35355 0.646453C6.15829 0.45119 5.84171 0.45119 5.64645 0.646453L5 1.2929L4.35355 0.646453C4.15829 0.45119 3.84171 0.45119 3.64645 0.646453L3 1.2929L2.35355 0.646453ZM2.13698 1.84409L2.64645 2.35356C2.84171 2.54882 3.15829 2.54882 3.35355 2.35356L4 1.70711L4.64645 2.35356C4.84171 2.54882 5.15829 2.54882 5.35355 2.35356L6 1.70711L6.64645 2.35356C6.84171 2.54882 7.15829 2.54882 7.35355 2.35356L8 1.70711L8.64645 2.35356C8.84171 2.54882 9.15829 2.54882 9.35355 2.35356L10 1.70711L10.6464 2.35356C10.8417 2.54882 11.1583 2.54882 11.3536 2.35356L12 1.70711L12.6464 2.35356C12.8417 2.54882 13.1583 2.54882 13.3536 2.35356L13.863 1.84409L14 2.11804V15H2V2.11804L2.13698 1.84409Z"
        fill="black"
      />
    </svg>
  );
};
export const HomeSvg = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 3.29288L14 9.29288V13.5C14 14.3284 13.3284 15 12.5 15H3.5C2.67157 15 2 14.3284 2 13.5V9.29288L8 3.29288Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13 2.49999V5.99999L11 3.99999V2.49999C11 2.22384 11.2239 1.99999 11.5 1.99999H12.5C12.7761 1.99999 13 2.22384 13 2.49999Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.29289 1.49999C7.68342 1.10946 8.31658 1.10946 8.70711 1.49999L15.3536 8.14643C15.5488 8.34169 15.5488 8.65828 15.3536 8.85354C15.1583 9.0488 14.8417 9.0488 14.6464 8.85354L8 2.20709L1.35355 8.85354C1.15829 9.0488 0.841709 9.0488 0.646447 8.85354C0.451184 8.65828 0.451184 8.34169 0.646447 8.14643L7.29289 1.49999Z"
        fill="black"
      />
    </svg>
  );
};
export const StatusCoverSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xlink="http://www.w3.org/1999/xlink"
      width="1366"
      height="1340"
    >
      <defs>
        <path id="A" d="M0 0h1368v1366H0z" />
      </defs>
      <g transform="translate(-2)" fill="none" fill-rule="evenodd">
        <mask id="B" fill="#fff">
          <use href="#A" />
        </mask>
        <g mask="url(#B)">
          <path
            d="M695.064-578c534.575 0 967.934 429.36 967.934 959s-433.36 959-967.934 959S-272.87 910.64-272.87 381 160.5-578 695.064-578zm126.4 182.887c-389.36 0-705 312.726-705 698.493s315.64 698.493 705 698.493 705-312.726 705-698.493-315.64-698.493-705-698.493z"
            fill="#517ead"
          />
          <ellipse
            fill="#2f4766"
            cx="1302.5"
            cy="-49.476"
            rx="322.5"
            ry="319.523"
          />
        </g>
      </g>
    </svg>
  );
};
export const PhoneSvg = () => {
  return (
    <svg
      stroke="#fff"
      fill="#fff"
      stroke-width="0"
      viewBox="0 0 24 24"
      size="20"
      class="phone-icon"
      height="20"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z"></path>
    </svg>
  );
};
export const EditSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8536 0.146447C12.6583 -0.0488155 12.3417 -0.0488155 12.1465 0.146447L10.5 1.7929L14.2071 5.50001L15.8536 3.85355C16.0488 3.65829 16.0488 3.34171 15.8536 3.14645L12.8536 0.146447Z"
        fill="black"
      />
      <path
        d="M13.5 6.20711L9.7929 2.50001L3.29291 9H3.5C3.77614 9 4 9.22386 4 9.5V10H4.5C4.77614 10 5 10.2239 5 10.5V11H5.5C5.77614 11 6 11.2239 6 11.5V12H6.5C6.77614 12 7 12.2239 7 12.5V12.7071L13.5 6.20711Z"
        fill="black"
      />
      <path
        d="M6.03166 13.6755C6.01119 13.6209 6 13.5617 6 13.5V13H5.5C5.22386 13 5 12.7761 5 12.5V12H4.5C4.22386 12 4 11.7761 4 11.5V11H3.5C3.22386 11 3 10.7761 3 10.5V10H2.5C2.43827 10 2.37915 9.98881 2.32455 9.96835L2.14646 10.1464C2.09858 10.1943 2.06092 10.2514 2.03578 10.3143L0.0357762 15.3143C-0.0385071 15.5 0.00502989 15.7121 0.146461 15.8536C0.287892 15.995 0.500001 16.0385 0.68571 15.9642L5.68571 13.9642C5.74858 13.9391 5.80569 13.9014 5.85357 13.8536L6.03166 13.6755Z"
        fill="black"
      />
    </svg>
  );
};
export const ArrowSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.77082 12.1103L13.7822 9.157C14.2059 8.90727 14.2059 8.29272 13.7822 8.043L9.77082 5.08972C9.34534 4.83898 8.8 5.14225 8.8 5.64672V6.65H2V10.55H8.8V11.5533C8.8 12.0577 9.34534 12.361 9.77082 12.1103Z"
        fill="white"
      />
    </svg>
  );
};
export const DeliverySvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8535 0.146446C15.995 0.287877 16.0385 0.499986 15.9642 0.685695L10.1454 15.2328C9.91841 15.8003 9.14441 15.8725 8.81629 15.3569L5.63782 10.3622L0.643079 7.18371C0.127464 6.85559 0.199737 6.08159 0.767192 5.8546L15.3143 0.035761C15.5 -0.0385224 15.7121 0.00501463 15.8535 0.146446ZM6.63716 10.0699L9.39807 14.4085L14.1311 2.57604L6.63716 10.0699ZM13.424 1.86893L1.59148 6.60192L5.93005 9.36283L13.424 1.86893Z"
        fill="black"
      />
    </svg>
  );
};
export const CardPlaneSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 4C2.77614 4 3 3.77614 3 3.5C3 3.22386 2.77614 3 2.5 3C2.22386 3 2 3.22386 2 3.5C2 3.77614 2.22386 4 2.5 4Z"
        fill="black"
      />
      <path
        d="M4.5 3.5C4.5 3.77614 4.27614 4 4 4C3.72386 4 3.5 3.77614 3.5 3.5C3.5 3.22386 3.72386 3 4 3C4.27614 3 4.5 3.22386 4.5 3.5Z"
        fill="black"
      />
      <path
        d="M5.5 4C5.77614 4 6 3.77614 6 3.5C6 3.22386 5.77614 3 5.5 3C5.22386 3 5 3.22386 5 3.5C5 3.77614 5.22386 4 5.5 4Z"
        fill="black"
      />
      <path
        d="M2 1C0.895431 1 0 1.89543 0 3V13C0 14.1046 0.895431 15 2 15H14C15.1046 15 16 14.1046 16 13V3C16 1.89543 15.1046 1 14 1H2ZM15 3V5H1V3C1 2.44772 1.44772 2 2 2H14C14.5523 2 15 2.44772 15 3ZM2 14C1.44772 14 1 13.5523 1 13V6H15V13C15 13.5523 14.5523 14 14 14H2Z"
        fill="black"
      />
    </svg>
  );
};
export const CoinSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 9.511C5.5763 10.4645 6.32909 11.2078 7.6821 11.2958V12H8.2823V11.291C9.68108 11.1932 10.5 10.445 10.5 9.35941C10.5 8.37164 9.87436 7.86308 8.75534 7.59902L8.2823 7.48655V5.56968C8.8825 5.63814 9.26399 5.96577 9.35554 6.42054H10.4084C10.3321 5.50122 9.54374 4.7824 8.2823 4.70416V4H7.6821V4.71883C6.48677 4.83619 5.67294 5.55501 5.67294 6.57213C5.67294 7.47188 6.27823 8.04401 7.28535 8.27873L7.6821 8.37653V10.4108C7.06663 10.3178 6.65972 9.98044 6.56816 9.511H5.5ZM7.67701 7.34474C7.08698 7.20782 6.76653 6.9291 6.76653 6.50856C6.76653 6.03912 7.11241 5.68704 7.6821 5.58435V7.34474H7.67701ZM8.36877 8.5379C9.08596 8.70416 9.41658 8.97311 9.41658 9.44743C9.41658 9.99022 9.00458 10.3619 8.2823 10.4303V8.51834L8.36877 8.5379Z"
        fill="black"
      />
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="black"
      />
      <path
        d="M8 13.5C4.96243 13.5 2.5 11.0376 2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
        fill="black"
      />
    </svg>
  );
};
export const QuestionSvg = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="black"
      />
      <path
        d="M5.25511 5.78615C5.24752 5.92237 5.3599 6.03271 5.49634 6.03271H6.32082C6.45889 6.03271 6.56868 5.92013 6.58723 5.78331C6.67618 5.12718 7.1265 4.64893 7.92922 4.64893C8.61477 4.64893 9.24318 4.9917 9.24318 5.81689C9.24318 6.45166 8.86867 6.74365 8.27834 7.18799C7.60549 7.67676 7.07229 8.24805 7.11037 9.1748L7.11334 9.39161C7.11521 9.52833 7.22658 9.63818 7.36332 9.63818H8.17434C8.31241 9.63818 8.42434 9.52625 8.42434 9.38818V9.28271C8.42434 8.56543 8.69729 8.35596 9.43361 7.79736C10.043 7.33398 10.6778 6.81982 10.6778 5.74072C10.6778 4.22998 9.40188 3.5 8.00539 3.5C6.73831 3.5 5.34964 4.09061 5.25511 5.78615ZM6.81203 11.5488C6.81203 12.082 7.23732 12.4756 7.82131 12.4756C8.43068 12.4756 8.84963 12.082 8.84963 11.5488C8.84963 10.9966 8.43068 10.6094 7.82131 10.6094C7.23732 10.6094 6.81203 10.9966 6.81203 11.5488Z"
        fill="black"
      />
    </svg>
  );
};
export const UploadSvg = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.5 6C3.22386 6 3 6.22386 3 6.5V14.5C3 14.7761 3.22386 15 3.5 15H12.5C12.7761 15 13 14.7761 13 14.5V6.5C13 6.22386 12.7761 6 12.5 6H10.5C10.2239 6 10 5.77614 10 5.5C10 5.22386 10.2239 5 10.5 5H12.5C13.3284 5 14 5.67157 14 6.5V14.5C14 15.3284 13.3284 16 12.5 16H3.5C2.67157 16 2 15.3284 2 14.5V6.5C2 5.67157 2.67157 5 3.5 5H5.5C5.77614 5 6 5.22386 6 5.5C6 5.77614 5.77614 6 5.5 6H3.5Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.64645 0.146447C7.84171 -0.0488155 8.15829 -0.0488155 8.35355 0.146447L11.3536 3.14645C11.5488 3.34171 11.5488 3.65829 11.3536 3.85355C11.1583 4.04882 10.8417 4.04882 10.6464 3.85355L8.5 1.70711V10.5C8.5 10.7761 8.27614 11 8 11C7.72386 11 7.5 10.7761 7.5 10.5V1.70711L5.35355 3.85355C5.15829 4.04882 4.84171 4.04882 4.64645 3.85355C4.45118 3.65829 4.45118 3.34171 4.64645 3.14645L7.64645 0.146447Z"
        fill="black"
      />
    </svg>
  );
};
